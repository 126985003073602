import React, { useState, useEffect } from "react";
import { sample } from "../assets";
import { auth } from "../firebase-config";
import FullPageLoader from "../components/FullPageLoader/FullPageLoader";
import LoginModule from "../modules/LoginModule";
import { AiFillLinkedin } from "react-icons/ai";

const Welcome = () => {
  const [loading, setLoading] = useState(false);
  const [secret, setSecret] = useState("");

  return loading ? (
    <>
      <FullPageLoader
        text="Loading SocialSense"
        subText={
          <>
            Hold tight as we get your{" "}
            <span className=" text-goldenTextColor">superpowers</span> ready.
          </>
        }
      />
    </>
  ) : (
    <>
      <div className=" bg-secondaryGradient w-screen h-screen flex  ">
        <div className=" w-screen md:w-[45%] ">
          <LoginModule />
        </div>
        <div className=" bg-primaryBlackShade w-0 md:w-[55%] h-screen login-right lex flex-col  items-center py-10">
          <p className=" md:text-4xl flex-grow  text-white text-center flex items-center flex-col justify-center ">
            <div>
              <span className=" text-goldenTextColor">
                Amplify your Personal Brand:
              </span>
            </div>
            <div className=" flex items-center md:text-3xl">
              <span>Nurture Genuine Connections on LinkedIn</span>
              <span className=" mr-2">
                <AiFillLinkedin size={40} />
              </span>
            </div>
          </p>
          <div className=" w-full">
            <img src={sample} className=" w-full" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
