// index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/css/main.css"
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { CookiesProvider } from "react-cookie";
import { NextUIProvider } from "@nextui-org/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CookiesProvider>
    <NextUIProvider>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </NextUIProvider>
  </CookiesProvider>
);
