import "./App.css";
import { Route, Routes } from "react-router-dom";
import Welcome from "./Pages/WelcomeScreen";
import Dashboard from "./Pages/Home";
import Customers  from "./Pages/Customers";
import TrialUsers from "./Pages/TrialUsers";
import CustomerDetailsPage from "./Pages/CustomerDetailsPage";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./Router/PrivateRoute";
import PublicRoute from "./Router/PublicRoute";
import Layout from "./components/Layout";

const App = () => {
  return (
    <AuthProvider>
      <div className="w-screen h-screen overflow-hidden flex flex-col">
        <div className="flex body">
          <Routes>
            <Route path="/login" element={
                <PublicRoute>
                  <Welcome />
                </PublicRoute>
              }  />
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <Layout>
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/customers" element={<Customers />} />
                      <Route path="/trial-users" element={<TrialUsers />} />
                      <Route path="/customer-details/:id" element={<CustomerDetailsPage />} />
                    </Routes>
                  </Layout>
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </AuthProvider>
  );
};

export default App;
