import React, { useState, useEffect } from "react";
import DateRangePickerComponent from "../components/DateRangePicker";
import { Card, Tooltip } from '@nextui-org/react';
import { getMetricsData } from "../Service/api";

const Home = () => {
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState({ start_date: null, end_date: null });

  const fetchMetricsData = async (start, end) => {
    // Function to format date as "dd-mm-yyyy"
    function formatDate(date) {
      let day = date.getDate().toString().padStart(2, '0');
      let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
      let year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }

    try {
      const data = { start_date: formatDate(start), end_date: formatDate(end) };
      console.log(data);
      const response = await getMetricsData(data);
      console.log('API Response:', response);
      setMetrics(response.payload || []); // Ensure that we set an array in case the response is null/undefined
    } catch (error) {
      console.error("Error fetching metrics data:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Get today's date
    let today = new Date();
    // Get the date of the same day last month
    let lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    if (today.getDate() !== lastMonth.getDate()) {
      // Handle month with fewer days than the current date (e.g., from 31st to 30th or 28th/29th)
      lastMonth.setDate(0);
    }

    setDateRange({ start_date: lastMonth, end_date: today });
    fetchMetricsData(lastMonth, today);
  }, []);

  useEffect(() => {
    if (dateRange.start_date && dateRange.end_date) {
      fetchMetricsData(dateRange.start_date, dateRange.end_date);
    }
  }, [dateRange]);

  const handleDateChange = (start, end) => {
    setDateRange({ start_date: start, end_date: end });
  };

  const handleResetDates = () => {
    let today = new Date();
    let lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    if (today.getDate() !== lastMonth.getDate()) {
      lastMonth.setDate(0);
    }
    setDateRange({ start_date: lastMonth, end_date: today });
  };

  if (loading) {
    return <div className="text-white">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">Error: {error.message}</div>;
  }

  return (
    <div className="bg-black min-h-screen p-6">
      <div className="text-white text-left mb-6">
        <h1 className="text-xl">Admin Dashboard</h1>
        <h1>A centralized platform for managing and monitoring operations, user activities, and data analytics.</h1>
      </div>
      <div className="flex justify-end mb-4">
        <DateRangePickerComponent 
          startDate={dateRange.start_date}
          endDate={dateRange.end_date}
          onDateChange={handleDateChange}
          onResetDates={handleResetDates}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {metrics && metrics.map((metric, index) => (
          <Card key={index} className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span></span>
              <Tooltip content="More info">
                <span className="cursor-pointer text-gray-400">&#9432;</span>
              </Tooltip>
            </div>
            <div className="text-center my-4">
              <h2 className="text-4xl font-bold">{metric.value}</h2>
              <p className="text-lg">{metric.label}</p>
            </div>
            {metric.change && (
              <div className="text-center text-sm text-gray-400">
                {metric.change}
              </div>
            )}
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Home;
