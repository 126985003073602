import User from "../models/userModel";
import { auth, db } from "../firebase-config";


const PAGE_SIZE = 10;


export const getCurrentUserIdToken = async () => {
    let idToken = "";
    if (auth.currentUser) {
      idToken = await auth.currentUser.getIdToken();
    }
    return idToken;
  };

export const userDataFirebase = async () => {
    const user_Id = getCurrentUserId();
    const userData = await User.getUserById(user_Id);
    // Set this data in a state 
    return userData;
  };

  export const getCurrentUserId = () => {
    if (auth.currentUser) {
        return auth.currentUser.uid;
    } else {
      return "";
    }
  };