import axios from "axios";
import {config} from "../config"
import { getCurrentUserIdToken } from ".";

const api = axios.create({
  baseURL: config.apiBaseURL,
  timeout: 30000
});

export const addAuthToken = async () => {
  try {
    const authToken = await getCurrentUserIdToken();
    api.defaults.headers.common['Authorization'] = authToken
    ? 'Bearer ' + authToken
    : '';
    api.defaults.headers['Authorization'] = authToken
    ? 'Bearer ' + authToken
    : '';
  } catch (e) {
    api.defaults.headers.common['Authorization'] = '';
    api.defaults.headers['Authorization'] = '';
  }
}

export const GET = async (url) => {
  let result = {};
  await addAuthToken();
  try {
    const response = await api.get(url);
    result.success = true;
    result.payload = response.data;
  } catch (e) {
    result.success = false;
    result.payload = {
      code: e.response ? e.response.code : "UNKNOWN_ERROR",
      data: e.response
        ? e.response.data
        : {
            message: "Some unknown error occurred! Please try again.",
          },
    };
  }

  return result;
};

export const POST = async (url, data, extendTimeout = false) => {
  await addAuthToken();
  let result = {};
  if (extendTimeout) {
    api.defaults.timeout += 60000; 
  }
  try {
    const response = await api.post(url, data);
    result.success = true;
    result.payload = response.data;
  } catch (e) {
    result.success = false;
    result.payload = {
      code: e.response ? e.response.code : "UNKNOWN_ERROR",
      data: e.response
        ? e.response.data
        : {
            message: "Some unknown error occurred! Please try again.",
          },
    };
  }

  return result;
};

export const PUT = async (url, data) => {
  await addAuthToken();
  let result = {};
  try {
    const response = await api.put(url, data);
    result.success = true;
    result.payload = response.data;
  } catch (e) {
    result.success = false;
    result.payload = {
      code: e.response ? e.response.code : "UNKNOWN_ERROR",
      data: e.response
        ? e.response.data
        : {
            message: "Some unknown error occurred! Please try again.",
          },
    };
  }

  return result;
};

export const DELETE = async (url, data) => {
  await addAuthToken();
  let result = {};
  try {
    const response = await api.delete(url, data);
    result.success = true;
    result.payload = response.data;
  } catch (e) {
    result.success = false;
    result.payload = {
      code: e.response ? e.response.code : "UNKNOWN_ERROR",
      data: e.response
        ? e.response.data
        : {
            message: "Some unknown error occurred! Please try again.",
          },
    };
  }

  return result;
};
