import React from 'react';

export default function CustomerDetails({ customer }) {
  return (
      <div className="max-w-7xl mx-auto bg-gray-900 rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center space-x-4">
            <img src={customer.profile_picture} alt="Profile" className="w-16 h-16 rounded-full" />
            <div>
              <p className="text-white text-lg"><strong>Name:</strong> {customer.full_name}</p>
              <p className="text-white text-lg"><strong>Email:</strong> {customer.email}</p>
              <p className="text-white text-lg"><strong>Client Type:</strong> {customer.clientType}</p>
            </div>
          </div>
          <div>
            <p className="text-white text-lg"><strong>Plan Start Date:</strong> NA</p>
            <p className="text-white text-lg"><strong>Plan End Date:</strong> NA</p>
          </div>
        </div>
        <div className="mb-4">
          <p className="text-green-500 text-lg"><strong>Subscription Status:</strong> {customer.subscription_status}</p>
        </div>
        <div className="flex justify-between mb-6">
          <button className="bg-gray-800 text-white py-2 px-4 rounded-lg">Edit Details</button>
          <button className="bg-gray-800 text-white py-2 px-4 rounded-lg">Add Credit</button>
          <button className="bg-gray-800 text-white py-2 px-4 rounded-lg">Cancel Subscription</button>
          <button className="bg-gray-800 text-white py-2 px-4 rounded-lg">Refund</button>
          <button className="bg-red-500 text-white py-2 px-4 rounded-lg">Restrict Account</button>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          <div className="bg-gray-800 p-4 rounded-lg flex justify-between">
            <p className="text-white text-lg">Post Credits</p>
            <p className="text-white text-lg">520 Credits</p>
          </div>
          <div className="bg-gray-800 p-4 rounded-lg flex justify-between">
            <p className="text-white text-lg">Posts Generated (Lifetime)</p>
            <p className="text-white text-lg">25</p>
          </div>
          <div className="bg-gray-800 p-4 rounded-lg flex justify-between">
            <p className="text-white text-lg">Credits Spent (Lifetime)</p>
            <p className="text-white text-lg">{customer.creditsSpentLifetime}</p>
          </div>
          <div className="bg-gray-800 p-4 rounded-lg flex justify-between">
            <p className="text-white text-lg">Posts Generated (This Month)</p>
            <p className="text-white text-lg">{customer.postsGeneratedThisMonth}</p>
          </div>
          <div className="bg-gray-800 p-4 rounded-lg flex justify-between">
            <p className="text-white text-lg">Credits Spent (This Month)</p>
            <p className="text-white text-lg">{customer.creditsSpentThisMonth}</p>
          </div>
          <div className="bg-gray-800 p-4 rounded-lg flex justify-between">
            <p className="text-white text-lg">Credits Refunded</p>
            <p className="text-white text-lg">{customer.creditsRefunded}</p>
          </div>
          <div className="bg-gray-800 p-4 rounded-lg flex justify-between">
            <p className="text-white text-lg">Total Spent</p>
            <p className="text-white text-lg">{customer.totalSpent}</p>
          </div>
          <div className="bg-gray-800 p-4 rounded-lg flex justify-between">
            <p className="text-white text-lg">Joining Date</p>
            <p className="text-white text-lg">{customer.created_at ? customer.created_at.split('T')[0] : ""}</p>
          </div>
          {/* Add additional fields as needed */}
          <div className="bg-gray-800 p-4 rounded-lg flex justify-between">
            <p className="text-white text-lg">Lorem Ipsum</p>
            <p className="text-white text-lg">123</p>
          </div>
        </div>
      </div>
  );
}
