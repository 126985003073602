import * as RestAPI from "./rest";


export const userLogin = (data) => {
    const response = RestAPI.POST("api/v1/user/", data);
    return response;
};

export const getMetricsData = (data) => {
    const response = RestAPI.POST("api/v1/admin-dashboard/get-metrics/", data);
    return response;
}

export const getFreeTrialUsers = () => {
    const response = RestAPI.GET("api/v1/admin-dashboard/free-trial-users/")
    return response;
}

export const getCustomersData = () => {
    const response = RestAPI.GET("api/v1/admin-dashboard/paid-customers-data/")
    return response;
}


export const addCustomer = (data) => {
    const response = RestAPI.POST("api/v1/admin-dashboard/add-customer/", data)
    return response
}