export const firebaseConfig = {
  apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID,
};

export const atomicPricing = {
  id:process.env.REACT_APP_DEV_ATOMICPRICING_ID,
  site:process.env.REACT_APP_DEV_ATOMICPRICING_SITE,
  pricingpage:process.env.REACT_APP_DEV_ATOMICPRICING_PRICING_PAGE,
  viewport_defaultheight:process.env.REACT_APP_DEV_ATOMICPRICING_VEIWPORT_DEFAULTHEIGHT,
  showcurrencydropdown:process.env.REACT_APP_DEV_ATOMICPRICING_SHOW_CURRENCY_DROPDOWN,
}

export const chargebeeURL =process.env.REACT_APP_DEV_CHARGEBEE_URL;

export const apiBaseURL = process.env.REACT_APP_DEV_BACKEND_BASE_URL;

export const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
