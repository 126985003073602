import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCustomersData } from '../Service/api';
import {
  Tooltip,
  Button,
  Input,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@nextui-org/react';

const FreeTrialUsersTable = () => {
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getCustomersData();
        setMetrics(response.payload);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="text-white">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">Error: {error.message}</div>;
  }

  const handleRowClick = (customer) => {
    navigate(`/customer-details/${customer.id}`, { state: { customer } });
  };

  return (
    <div className="bg-black min-h-screen p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-xl text-white">Converted Customers</h1>
        <div className="flex space-x-5">
          <div className="flex-grow p-1">
            <input
              type="search"
              className="bg-transparent text-white disabled:cursor-not-allowed outline-none border-none !w-full"
              placeholder="Search by Name or Email"
            />
          </div>
          <Button auto shadow bordered className="bg-gray-800 text-white">
            Search
          </Button>
          <Button
            auto
            shadow
            bordered
            className="bg-gray-800 text-white"
            onClick={() => {
              onOpen();
            }}
          >
            Add Customer
          </Button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <div className="overflow-y-auto max-h-96">
          <table className="min-w-full bg-black text-white">
            <thead>
              <tr>
                <th className="py-2 px-4 text-left">Customer Name</th>
                <th className="py-2 px-4 text-left">Email</th>
                <th className="py-2 px-4 text-left">Status</th>
                <th className="py-2 px-4 text-left">Starting Date</th>
                <th className="py-2 px-4 text-left">Validity</th>
                <th className="py-2 px-4 text-left">Plan</th>
              </tr>
            </thead>
            <tbody>
              {metrics.map((metric, index) => (
                <tr key={index} className="border-b border-gray-700 cursor-pointer hover:bg-tertiaryBlackShade" onClick={() => handleRowClick(metric)}>
                  <td className="py-2 px-4">{metric.full_name}</td>
                  <td className="py-2 px-4">{metric.email}</td>
                  <td className="py-2 px-4">
                    <span className={`py-1 px-2 rounded-full text-xs ${metric.subscription_status === "INACTIVE" ? "bg-red-500" : "bg-green-500"}`}>
                      {metric.subscription_status}
                    </span>
                  </td>
                  <td className="py-2 px-4">
                    {metric.created_at ? metric.created_at.split('T')[0] : ""}
                  </td>
                  <td className="py-2 px-4">
                    {metric.free_trial_ending_at ? metric.free_trial_ending_at.split('T')[0] : ""}
                  </td>
                  <td className="py-2 px-4">{metric.plan}</td>
                  <td className="py-2 px-4">
                    <Dropdown>
                      <DropdownTrigger>
                        <Button flat>Action</Button>
                      </DropdownTrigger>
                      <DropdownMenu aria-label="Static Actions">
                        <DropdownItem key="credits">Add Credits</DropdownItem>
                        <DropdownItem key="refund">Refund</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {isOpen && (
        <Modal
          placement={"top-center"}
          size="lg"
          centered
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          isDismissable={false}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  {" "}
                  <h1 className="text-xl">
                    Add a customer on{" "}
                    <span className="text-blue-500">SocialSense</span>
                  </h1>
                </ModalHeader>
                <ModalBody>
                  <Input
                    clearable
                    fullWidth
                    placeholder="John"
                    label="First Name"
                    className="text-white"
                  />
                  <Input
                    clearable
                    fullWidth
                    placeholder="Cena"
                    label="Last Name"
                    className="text-white"
                  />
                  <Input
                    clearable
                    fullWidth
                    placeholder="someone@example.com"
                    label="Email Address"
                    type="email"
                    className="text-white"
                  />
                  <Input
                    fullWidth
                    readOnly
                    initialValue="SocialSense"
                    label="Organization Name"
                    className="text-white"
                  />

                  {/* Comment out Dropdown temporarily */}
                  {/* <Dropdown>
        <DropdownTrigger>
          <Input readOnly value={selectedPlan || "Select Plan/Trial"} label="Plan" className="text-white" />
        </DropdownTrigger>
        <DropdownMenu aria-label="Static Actions" onAction={(key) => setSelectedPlan(key)}>
          <DropdownItem key="trial">Trial</DropdownItem>
          <DropdownItem key="basic">Basic</DropdownItem>
          <DropdownItem key="premium">Premium</DropdownItem>
        </DropdownMenu>
      </Dropdown> */}

                  <Input
                    fullWidth
                    label="Choose Duration"
                    type="date"
                    className="text-white"
                  />
                  <Input
                    clearable
                    fullWidth
                    placeholder="Amount of Post Credits"
                    label="Post Credits"
                    type="number"
                    className="text-white"
                  />
                </ModalBody>
                <ModalFooter>
                  <Button auto flat color="error" onClick={onClose}>
                    Close
                  </Button>
                  <Button auto onClick={() => {
                    onClose()
                    
                    }} >
                    Done
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default FreeTrialUsersTable;
