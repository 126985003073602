import React from "react";
import { useAuth } from "../contexts/AuthContext";
import SideNav from "./SideNav";
import Header from "./Header";

const Layout = ({ children }) => {
  const { currentUser } = useAuth();

  return (
    <div className="flex w-screen h-screen">
      <div id="main-sidebar-container">
        {currentUser && <SideNav />}
      </div>
      <div className="main-content-container flex-grow">
        {currentUser && <Header />}
        {children}
      </div>
    </div>
  );
};

export default Layout;
