import React, { useEffect, useState } from "react";
import { Avatar } from "../assets";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IoIosArrowDown, IoMdAddCircle } from "react-icons/io";
import { MdClose, MdDoNotDisturb } from "react-icons/md";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { FaArrowLeft } from "react-icons/fa6";
import { auth } from "../firebase-config";
import { signOut } from "firebase/auth";
import { useAuth } from "../contexts/AuthContext";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  User,
  Progress,
} from "@nextui-org/react";

const Header = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [openWorkspaceSetting, setOpenWorkspaceSetting] = useState(false);
  const [openMemberSetting, setOpenMemberSetting] = useState(false);



  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <>
      <div className=" w-full pt-3 pb-1 px-3 absolute md:relative -z-40 bg-white  dark:bg-primaryBlackShade text-black dark:text-white h-max !flex justify-end  md:z-40  items-center only-show-on-large-screen ">
        {/* <div className=" bg-tertiaryLightShade dark:bg-tertiaryBlackShade px-3 py-2 mx-2 rounded-xl flex items-center">
          <span className="gradientText mx-1">{currentUser.posts} </span>
          <span className=" mx-1">Post Credits</span>
        </div> */}
        <div
          className=" bg-tertiaryLightShade dark:bg-tertiaryBlackShade px-3 py-1.5 rounded-xl flex items-center cursor-pointer "
          onClick={() => {
            setOpenWorkspaceSetting(!openWorkspaceSetting);
          }}
        >
          <img
            src={currentUser.photoURL ? currentUser.photoURL : Avatar}
            className=" rounded-full h-6 w-6 mx-2 my-1"
            referrerPolicy="no-referrer"
            alt=""
          />
          <span>{currentUser.displayName}</span>
          <IoIosArrowDown className=" mx-2" />
        </div>
      </div>
      <div className=" absolute  right-0">
        {openWorkspaceSetting && (
          <div className="relative z-50 w-screen h-screen">
            <div
              className="absolute z-40 w-screen h-screen"
              onClick={() => {
                setOpenWorkspaceSetting(false);
                setOpenMemberSetting(false);
              }}
            ></div>
            <div className="absolute right-3  max-h-3/6 w-[15%] z-50 rounded-xl px-2 py-2 mx-1 my-2 text-black dark:text-white bg-secondaryLightShade dark:bg-tertiaryBlackShade overflow-y-scroll border-1 border-goldenBorder">
              <div
                className=" cursor-pointer flex items-center w-full overflow-x-hidden whitespace-nowrap text-md hover:bg-tertiaryLightShade dark:hover:bg-secondaryBlackShade px-3 py-2 rounded-lg"
                onClick={() => handleLogout()}
              >
                <div>
                  <MdDoNotDisturb size={18} />
                </div>
                <div className=" mx-2 ">Logout</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
