import React, { useState } from "react";
import { Logo, googleIcon, newLogo } from "../assets";
import { handleGoogleLogin } from "../Service/loginManager";
import { config } from "../config";
import LogoLoader from "../components/LogoLoader/LogoLoader";
import { useNavigate } from "react-router-dom";

function LoginModule() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    const user = await handleGoogleLogin(navigate); // Pass navigate function here
    setLoading(false);
    if (user) {
      navigate('/');
    }
  };

  return (
    <>
      {loading && (
        <div className="backdrop-blur-sm h-screen w-screen flex items-center justify-center absolute top-0 left-0 bg-black/40 z-50">
          <LogoLoader />
        </div>
      )}
      <div className={`flex flex-col items-center text-center w-full md:px-20 py-3 px-3 h-full font-Poppins  overflow-y-scroll`}>
        <div className=" flex justify-center md:justify-start items-center my-6 w-full ">
          <img className="my-1" alt="logo" src={Logo} height={40} width={40} />
          <img className="my-1 mx-2" src={newLogo} height={200} width={200} alt="socialsense" />
        </div>
        <div className=" w-full md:w-[80%] h-full md:h-max rounded-2xl shadow-primaryShadow md:px-10 flex flex-col  justify-center py-3 md:py-5">
          <div className=" flex-grow">
            <p className="w-full md:text-4xl text-2xl text-white font-Poppins  text-center md:mt-5">
              Welcome to <span className=" text-goldenTextColor">SocialSense</span>
            </p>
            <div className=" flex  justify-center">
              <button
                className="flex my-3 mx-3 items-center justify-center  rounded-full   font-Poppins font-semibold cursor-pointer w-max"
                // disabled={config.env === "development" && secretInput !== secret}
                onClick={handleLogin}
              >
                <img src={googleIcon} sizes={30} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginModule;
