import React from "react";
import "./FullPageLoader.css";
import LogoLoader from "../LogoLoader/LogoLoader";

function FullPageLoader({ text, subText }) {
  return (
    <div className="w-screen h-screen absolute top-0 left-0 bg-secondaryLightShade dark:bg-primaryBgColor flex justify-center items-center z-50">
      <div className="flex flex-col justify-center items-center dark:border-2 dark:border-goldenBorder bg-primaryLightShade dark:bg-transparent shadow-lightShadow md:w-3/6 h-max rounded-3xl md:py-3 py-20 mx-3">
        <LogoLoader />
        <div className="text-goldenTextColor md:text-5xl text-3xl  font-Poppins my-3">
          {text}
          <span className="dot-one"> .</span>
          <span className="dot-two"> .</span>
          <span className="dot-three"> .</span>
        </div>
        <p className=" text-black/70 dark:text-white/75 text-center text-xl md:text-2xl my-5 px-4">
          {subText}
        </p>
      </div>
    </div>
  );
}

export default FullPageLoader;
