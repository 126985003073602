import { db } from "../firebase-config";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";

// Define the User model

class User {
  constructor(
    linkedIn_id,
    full_name,
    first_name,
    last_name,
    email,
    profile_picture,
    x_designation,
    x_industry,
    x_preferred_prompts_tone,
    subscription,
    reward_logs,
    rewardPoints,
    earned_reward_points,
    x_prompts,
    x_custom_prompts,
    li_access_token,
    wa_interest,
    wa_creators,
    is_creator,
    creator,
    creator_id,
    posts,
    statistics,
    timezone,
    ai_preffered_langauge,
    billing_email,
    referral_code,
    profileUpdatedAt,
    recentPosts
  ) {
    this.linkedIn_id = linkedIn_id;
    this.full_name = full_name;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.profile_picture = profile_picture;
    this.x_designation = x_designation;
    this.x_industry = x_industry;
    this.x_preferred_prompts_tone = x_preferred_prompts_tone;
    this.subscription = subscription;
    this.reward_logs = reward_logs;
    this.rewardPoints = rewardPoints;
    this.earned_reward_points = earned_reward_points;
    this.x_prompts = x_prompts;
    this.x_custom_prompts = x_custom_prompts;
    this.li_access_token = li_access_token;
    this.wa_interest = wa_interest;
    this.wa_creators = wa_creators;
    this.is_creator = is_creator;
    this.creator = creator;
    this.creator_id = creator_id;
    this.posts = posts;
    this.statistics = statistics;
    this.timezone = timezone;
    this.ai_preffered_langauge = ai_preffered_langauge;
    this.billing_email = billing_email;
    this.referral_code = referral_code;
    this.profileUpdatedAt = profileUpdatedAt;
    this.recentPosts = recentPosts;
  }

  static async updateUserById(linkedIn_id, data) {
    let userRef = doc(db, "users", linkedIn_id);
    const userData = await updateDoc(userRef, data);
    return userData;
  }

  // Save the User data to Firestore
  save() {
    return db.collection("users").doc(this.linkedIn_id).set({
      linkedIn_id: this.linkedIn_id,
      full_name: this.full_name,
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      profile_picture: this.profile_picture,
      x_designation: this.x_designation,
      x_industry: this.x_industry,
      x_preferred_prompts_tone: this.x_preferred_prompts_tone,
      subscription: this.subscription,
      reward_logs: this.reward_logs,
      rewardPoints: this.rewardPoints,
      earned_reward_points: this.earned_reward_points,
      x_prompts: this.x_prompts,
      x_custom_prompts: this.x_custom_prompts,
      li_access_token: this.li_access_token,
      wa_interest: this.wa_interest,
      wa_creators: this.wa_creators,
      is_creator: this.is_creator,
      creator: this.creator,
      creator_id: this.creator_id,
      posts: this.posts,
      statistics: this.statistics,
      timezone: this.timezone,
      ai_preffered_langauge: this.ai_preffered_langauge,
      billing_email: this.billing_email,
      referral_code: this.referral_code,
      profileUpdatedAt: this.profileUpdatedAt,
    });
  }

  // Static method to fetch a User by ID from Firestore
  static async getUserById(linkedin_id) {

    let collectionName = "users"
    
    const docRef = doc(db, collectionName, linkedin_id);
    onSnapshot(docRef, (doc) => {
      return doc.data();
    });
    const docSnap = await getDoc(docRef);
    let userDoc = docSnap.data();
    return userDoc;
  }
}

export default User;
