import React from "react";
import { sideBtn } from "../../assets";
import "./LogoLoader.css";

function LogoLoader({ text }) {
  return (
    <div className=" flex flex-col justify-center items-center h-44">
      <div className="outer-wrapper  w-max p-1 m-1 rounded-full loader-clockwise">
        <div className=" bg-transparent rounded-full p-1">
          <div className="inner-wrapper  border-b-2 border-l-2 border-goldenTextColor  p-1 rounded-full loader-anticlockwise">
            <div className=" bg-transparent rounded-full p-1">
              <img src={sideBtn} className=" h-9 w-9" alt="" />
            </div>
          </div>
        </div>
      </div>
      {text && <p className=" text-center text-lg  text-black dark:text-white my-2">{text}</p>}
    </div>
  );
}

export default LogoLoader;
