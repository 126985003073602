import { auth } from "../firebase-config";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { userLogin } from "./api";
import { userDataFirebase } from ".";
import toast from "react-hot-toast";

const googleAuthProvider = new GoogleAuthProvider();
const source = window.top === window.self ? "WebApp" : "Extension";
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const handleGoogleLogin = async (navigate) => {
  const loadingToast = toast.loading("Please Wait...");
  const data = { timezone: timezone, source: source };

  try {
    const res = await signInWithPopup(auth, googleAuthProvider);
    const user = res.user;
    console.log("Logged in user:", user); // Log the user object

    const result = await userLogin(data);

    if (result.success && result.payload.success) {
      const userDetails = await userDataFirebase();
      console.log("User details from Firebase:", userDetails); // Log user details from Firebase
      toast.success("Login Successful!", { id: loadingToast });
      navigate('/');
    } else {
      toast.error("Something is Wrong, Try Again!", { id: loadingToast });
      await auth.signOut();
      return;
    }

    return user;
  } catch (err) {
    toast.error("Something is Wrong, Try Again!", { id: loadingToast });
    return null;
  }
};

export { handleGoogleLogin };
