import React from 'react'
import CustomerTable from '../components/CustomerTable'

export default function Customers() {

    return (
        <>
        <div className="bg-black min-h-screen p-6">
            <div className="text-white text-left mb-6">
                <h1 className="text-xl">Admin Dashboard</h1>
                <h1>A centralized platform for managing and monitoring operations, user activities, and data analytics.</h1>
            </div>
            <CustomerTable />
        </div>
        </>
    )
}
