import React from "react";
import { Logo, newLogo } from "../assets";
import { useNavigate } from "react-router-dom";

function SideNav() {
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-[#181818] dark:bg-tertiaryBlackShade flex flex-col px-2.5 text-white overflow-hidden relative h-full sidenav font-Poppins">
        <div className="rounded-lg overflow-y-scroll relative">
          <div className="flex justify-between items-center py-3">
            <div
              className="flex flex-row items-center justify-start py-1.5 md:w-full cursor-pointer"
              onClick={() => navigate("/")}
            >
              <img src={Logo} height={25} width={25} alt="" />
              <img src={newLogo} width={130} className="mx-2" alt="" />
            </div>
          </div>

          <div className="mt-5">
            <div
              className="cursor-pointer py-2 px-3 hover:bg-gray-700 rounded"
              onClick={() => navigate("/")}
            >
              Dashboard
            </div>
            <div
              className="cursor-pointer py-2 px-3 hover:bg-gray-700 rounded"
              onClick={() => navigate("/customers")}
            >
              Customers
            </div>
            <div
              className="cursor-pointer py-2 px-3 hover:bg-gray-700 rounded"
              onClick={() => navigate("/trial-users")}
            >
              Trial Users
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideNav;
