import { variable } from "./environment";
const ENV = process.env.REACT_APP_ENV || "development";

const envConfig = variable[ENV];

export const config = Object.assign(
  {
    env: ENV,
  },
  envConfig
);